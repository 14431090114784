.frame {
  fill: var(--Chart-frame__color);
}

.headerText {
  fill: var(--Chart-frame__color);
  font-family: var(--header-font-family);
  font-size: 2.2rem;
}

.subheaderText {
  fill: var(--Chart-frame__color);
  font-family: var(--header-font-family);
  font-size: 1rem;
}

.keyFont {
  font-family: var(--monospace-font-family);
  font-size: 1rem;
}

.valueFont {
  font-family: var(--value-font-family);
  font-size: 0.7rem;
}

.value {
  fill: var(--text-color);
  stroke: var(--text-color);
  stroke-linecap: square;
}

.speed {
  fill: var(--Chart-speed__color);
  stroke: var(--Chart-speed__color);
  stroke-linecap: square;
}

.accuracy {
  fill: var(--Chart-accuracy__color);
  stroke: var(--Chart-accuracy__color);
  stroke-linecap: square;
}

.complexity {
  fill: var(--Chart-complexity__color);
  stroke: var(--Chart-complexity__color);
  stroke-linecap: square;
}

.threshold {
  fill: var(--Chart-threshold__color);
  stroke: var(--Chart-threshold__color);
  stroke-linecap: square;
}

.hist_h {
  fill: var(--Chart-hist-h__color);
}

.hist_m {
  fill: var(--Chart-hist-m__color);
}

.hist_r {
  fill: var(--Chart-hist-r__color);
}
