.root {
  display: flex;
  flex-direction: column;
  padding-inline: 3rem;
  padding-block: 3rem 2rem;
}

.close {
  position: absolute;
  inset-inline-end: 1rem;
  inset-block-start: 1rem;
}

.footer {
  display: flex;
  align-items: center;
  margin-block-start: 1rem;
}

.prev + .next {
  margin-inline-start: 1rem;
}
