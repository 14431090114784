.numbered {
  counter-reset: h1;
}

.numbered h1:before {
  content: counter(h1) " ";
}

.numbered h1 {
  counter-increment: h1;
  counter-reset: h2;
}

.numbered h2:before {
  content: counter(h1) "." counter(h2) " ";
}

.numbered h2 {
  counter-increment: h2;
  counter-reset: h3;
}

.numbered h3:before {
  content: counter(h1) "." counter(h2) "." counter(h3) " ";
}

.numbered h3 {
  counter-increment: h3;
}
