@import "@keybr/widget/lib/index.less";

.root {
  display: inline-block;
  vertical-align: middle;
  inline-size: 4rem;
  min-block-size: @field-height;
  margin: 0;
  padding: 0;
  border: var(--separator-border);
  cursor: pointer;
}

.popup {
  color: var(--Popup__color);
  background-color: var(--Popup__background-color);
  box-shadow: var(--Popup--small__box-shadow);
}
