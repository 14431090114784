.statisticList {
  display: flex;
  gap: 1rem;
}

.statisticListItem {
  flex: 1 1 0;
  padding-inline: 1rem;
  padding-block: 1rem;
  border: var(--separator-border);
}

.itemName {
  display: block;
  color: var(--Name-color);
  font-size: 1rem;
  text-align: center;
}

.itemValue {
  display: block;
  color: var(--Value-color);
  font-family: var(--value-font-family);
  font-size: 2rem;
  text-align: center;
}
