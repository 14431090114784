.root {
  color: var(--Popup__color);
  background-color: var(--Popup__background-color);
  box-shadow: var(--Popup__box-shadow);
}

.arrow {
  inline-size: 20px;
  block-size: 20px;
  color: var(--Popup__color);
  background-color: var(--Popup__background-color);
  transform: translate(-10px, -10px) rotate(45deg);
}
