.icon {
  inline-size: 1rem;
  block-size: 1rem;
  fill: var(--text-color);
}

.happy .icon {
  fill: var(--Value--more__color);
}

.sad .icon {
  fill: var(--Value--less__color);
}
