.rule {
  position: relative;
  margin-inline-start: 1rem;
  padding-inline-start: 2rem;
  padding-block-start: 1rem;
  padding-block-end: 1rem;
  border-inline-start: var(--separator-border);
}

.ruleNumber {
  position: absolute;
  inset-inline-start: -1rem;
  inline-size: 2rem;
  block-size: 2rem;
  margin-block: 0.6rem;
  border-radius: 50%;
  color: var(--background-color);
  background-color: var(--text-color);
  text-align: center;
}

.example {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-block: 1rem;
}
