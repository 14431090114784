.root {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}

.item {
  inline-size: 0.8rem;
  block-size: 0.8rem;
  border-radius: 50%;
  background-color: var(--separator-color);

  & + & {
    margin-inline-start: 0.2rem;
  }
}

.current {
  background-color: var(--text-color);
}
