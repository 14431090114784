.spot {
  opacity: 0.7;
}

.spot_h {
  fill: var(--Chart-hist-h__color);
}

.spot_m {
  fill: var(--Chart-hist-m__color);
}

.spot_f {
  fill: var(--Chart-hist-r__color);
}
