:where(html),
[data-preview-theme] {
  --background-color: var(--primary);
  --background-image: none;
  --text-color: var(--secondary);
  --text-color-f1: var(--secondary-f1);
  --text-color-f2: var(--secondary-f2);
  --separator-color: var(--primary-d1);
  --separator-border: 1px solid var(--separator-color);

  --textinput__color: var(--text-color);
  --textinput--special__color: var(--text-color-f2);
  --textinput--hit__color: var(--text-color-f2);
  --textinput--miss__color: var(--error);
  --textinput-cursor__color: var(--background-color);
  --textinput-cursor__background-color: var(--textinput__color);

  --LessonKey--included__color: var(--secondary-d1);
  --LessonKey--included__background-color: transparent;
  --LessonKey--included__outline-color: transparent;
  --LessonKey--excluded__color: var(--secondary-d1);
  --LessonKey--excluded__background-color: var(--primary-d1);
  --LessonKey--excluded__outline-color: transparent;
  --LessonKey--uncalibrated__color: var(--secondary-d1);
  --LessonKey--uncalibrated__background-color: var(--primary-d1);
  --LessonKey--uncalibrated__outline-color: transparent;
  --LessonKey--focused__outline-color: var(--secondary-l2);
  --LessonKey--forced__outline-color: transparent;
  --LessonKey--current__outline-color: var(--secondary-l2);

  --Keyboard-frame__color: var(--primary-d1);
  --KeyboardKey-button__color: var(--primary);
  --KeyboardKey-button--depressed__color: var(--secondary);
  --KeyboardKey-symbol__color: var(--secondary);
  --KeyboardKey-symbol--depressed__color: var(--primary);
  --KeyboardKey-symbol--dead__color: #cc0000;
  --KeyboardKey-symbol--ligature__color: #6666ff;

  --Chart-frame__color: var(--primary-d2);

  --DailyGoal-bar__color: var(--primary-l2);
  --DailyGoal-frame__color: var(--primary-d2);

  --Calendar-caption__background-color: var(--primary-d1);
  --Calendar-headerCell__background-color: var(--primary-d2);
  --Calendar-cell--background-color: var(--primary-l1);

  --Player__background-color: var(--primary);
  --Player--me__background-color: var(--primary-d1);
  --Player--finished__background-color: transparent;
  --PlayerDetails__color: var(--secondary);
  --PlayerDetails__background-color: var(--primary-l1);

  --Name-color: var(--secondary-l2);
  --Value-color: var(--secondary-d1);
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;

  --Button__color: var(--primary-l2);
  --Button__background-color: var(--accent);
  --Button--focused__color: var(--primary-l2);
  --Button--focused__background-color: var(--accent-l2);

  --MenuItem__color: var(--primary-l2);
  --MenuItem__background-color: var(--accent-l1);
  --MenuItem--hover__color: var(--primary-l2);
  --MenuItem--hover__background-color: var(--accent-l2);
  --MenuItem--selected__color: var(--primary-l2);
  --MenuItem--selected__background-color: var(--accent-d1);

  --TextField__color: var(--secondary);
  --TextField__background-color: var(--primary-l1);
  --TextField__border-color: var(--primary-d1);
  --TextField--focused__color: var(--secondary);
  --TextField--focused__background-color: var(--primary-l2);
  --TextField--focused__border-color: var(--primary-d2);

  --Popup__color: var(--text-color);
  --Popup__background-color: var(--background-color);
  --Popup__box-shadow: 0 0.5rem 3rem var(--shadow-color);
  --Popup--small__box-shadow: 0 0rem 1rem var(--shadow-color);

  --Spotlight__background-color: #00000033;
  --Spotlight__border-color: var(--secondary-l2);
  --Spotlight-marker__shadow-color: var(--shadow-color);

  --Surface__box-shadow: none;
  --Surface__backdrop-filter: none;

  scrollbar-color: var(--secondary) var(--primary);

  ::selection {
    color: var(--primary);
    background-color: var(--secondary);
  }
}
