@import "../../styles/components.less";

.root {
  display: inline-block;
  vertical-align: middle;
  inline-size: 1.8rem;
  block-size: 1.8rem;
  fill: var(--Button__background-color);
}

.altIcon .root {
  fill: var(--Button__color);
}
