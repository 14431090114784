@import "../../styles/components.less";
@import "../../styles/text.less";

.root {
  color: var(--text-color);
  text-decoration: underline;
}

.disabled {
  .is-disabled();
}
