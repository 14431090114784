.root {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.calendar {
  border: var(--separator-border);
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  cursor: default;
}

.caption,
.headerCell,
.cell {
  margin: 0;
  padding: 0;
}

.caption {
  background-color: var(--Calendar-caption__background-color);
  text-align: center;
}

.headerCell {
  background-color: var(--Calendar-headerCell__background-color);
}

.cell {
  background-color: var(--Calendar-cell--background-color);
}

.item {
  display: block;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
}
