.root {
  /* Empty. */
}

.small {
  min-inline-size: 20rem;
  max-inline-size: 30rem;
  min-block-size: 5rem;
}

.large {
  inline-size: 36rem;
  min-block-size: 24rem;
}
