@import "palettes.less";

html[data-color="coffee"] {
  @colors: {
    @primary: #faecd7;
    @secondary: #262626;
    @accent: #4d4d4d;
    @error: #a34729;
  };

  --primary-d2: darken(@colors[@primary], 10);
  --primary-d1: darken(@colors[@primary], 5);
  --primary: @colors[@primary];
  --primary-l1: lighten(@colors[@primary], 5);
  --primary-l2: lighten(@colors[@primary], 10);
  --secondary-d1: darken(@colors[@secondary], 10);
  --secondary: @colors[@secondary];
  --secondary-l1: lighten(@colors[@secondary], 10);
  --secondary-l2: lighten(@colors[@secondary], 20);
  --secondary-f1: mix(@colors[@primary], @colors[@secondary], 20);
  --secondary-f2: mix(@colors[@primary], @colors[@secondary], 40);
  --accent-d2: darken(@colors[@accent], 20);
  --accent-d1: darken(@colors[@accent], 10);
  --accent: @colors[@accent];
  --accent-l1: lighten(@colors[@accent], 10);
  --accent-l2: lighten(@colors[@accent], 20);
  --error-d1: darken(@colors[@error], 10);
  --error: @colors[@error];
  --error-l1: lighten(@colors[@error], 10);
  --shadow-color: #00000044;
  --slow-key-color: #cf5f5f;
  --fast-key-color: #6fa882;
  --effort-color: #759ef0;
  .use-textinput-colors(@colors);
  .use-interface-colors(@colors);
  .use-chart-colors(@colors, 20);
  .use-keyboard-colors(@colors, 20);
  .use-syntax-colors();
  --background-image: url(../../assets/bg-coffee.svg);
  --Surface__box-shadow: 0 0.5rem 1rem var(--shadow-color);
  --Surface__backdrop-filter: blur(10px);
}
