.root {
  background-color: #000;
}

.saturation {
  position: relative;
  aspect-ratio: 1/1;
  outline: none;
}

.hue {
  position: relative;
  block-size: 2rem;
  outline: none;
}

.channel {
  position: relative;
  block-size: 2rem;
  outline: none;
}
