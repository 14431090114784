@import "@keybr/widget/lib/index.less";

.root {
  .is-blurred();
}

.root:focus-visible {
  .is-focused();
  outline-offset: 2px;
}
