.root {
  position: relative;
  margin-block-start: 1rem;
  background-color: var(--primary-l1);
}

.bar {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: var(--primary-d1);
}

.info {
  position: relative;
  inset: 0;
  text-align: center;
}
