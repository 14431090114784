@import "../../styles/components.less";

.root {
  /* Empty. */
}

.caption {
  /* Empty. */
}

.description {
  margin-inline: 0;
  margin-block: 0.5rem;
  padding-inline: 0;
  padding-block: 0;
  color: var(--text-color-f1);
  font-size: 0.9rem;
  text-align: center;
}

.legend {
  color: var(--text-color-f1);
  font-size: 0.9rem;
  text-align: center;
}
