.root {
  position: relative;
}

.bar {
  block-size: 0.5rem;
}

.bar.determined {
  background-color: var(--text-color);
}

.bar.intermediate {
  background: linear-gradient(
    -45deg,
    transparent 0rem,
    transparent calc(1rem * 2.828427125 / 2),
    var(--text-color) calc(1rem * 2.828427125 / 2),
    var(--text-color) calc(1rem * 2.828427125),
    transparent calc(1rem * 2.828427125)
  );
  background-size: 4rem 2rem;
  animation: stripesAnimation 0.5s linear infinite;
}

@keyframes stripesAnimation {
  0% {
    background-position: 0rem 0rem;
  }
  100% {
    background-position: 4rem 0rem;
  }
}
