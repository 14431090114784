.root {
  display: flex;
  margin-inline: 0;
  margin-block: 1rem;
  padding-inline: 1rem;
  padding-block: 1rem;
  border: var(--separator-border);
}

.coverImage {
  block-size: 8rem;
}

.details {
  flex: 1;
  margin-inline-start: 1rem;
}
