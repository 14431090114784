.indicator {
  min-inline-size: 20rem;
  text-align: center;
}

.indicatorName {
  font-size: 1rem;
  text-align: center;
}

.indicatorValue {
  border-block-end: var(--separator-border);
  line-height: 1;
  text-align: center;
}

.valueLabel {
  font-size: 5rem;
  font-weight: bold;
}

.unitLabel {
  font-size: 2rem;
  font-weight: normal;
}

.separator {
  margin-inline: 1rem;
  margin-block: 1rem;
  border-inline-start: var(--separator-border);
}
