.root {
  display: flex;
  gap: 1rem;
  align-items: center;
  max-inline-size: 40rem;
}

.gauge {
  position: relative;
  flex: 1;
  inline-size: 100%;
  block-size: 8px;
}

.bar {
  position: absolute;
  block-size: 8px;
  background-color: var(--DailyGoal-bar__color);
}

.frame {
  position: absolute;
  block-size: 8px;
  border: 1px solid var(--DailyGoal-frame__color);
}
