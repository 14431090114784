.toaster {
  display: flex;
  position: fixed;
  inset: 0;
  z-index: 1;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  inline-size: fit-content;
  block-size: fit-content;
  margin-inline: auto auto;
  margin-block: auto 0;
  padding: 3rem;
}

.toaster[hidden] {
  display: none;
}
