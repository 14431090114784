@field-height: 2rem;

.is-disabled() {
  opacity: 0.7;
}

.is-blurred() {
  outline: none;
}

.is-focused() {
  outline: 1px dotted var(--text-color);
}
