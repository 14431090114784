@import "palettes.less";

html[data-color="yellow"] {
  @colors: {
    @primary: #ffe666;
    @secondary: #26220d;
    @accent: #736626;
    @error: #b83d14;
  };

  --primary-d2: darken(@colors[@primary], 20);
  --primary-d1: darken(@colors[@primary], 10);
  --primary: @colors[@primary];
  --primary-l1: lighten(@colors[@primary], 5);
  --primary-l2: lighten(@colors[@primary], 10);
  --secondary-d1: darken(@colors[@secondary], 10);
  --secondary: @colors[@secondary];
  --secondary-l1: lighten(@colors[@secondary], 10);
  --secondary-l2: lighten(@colors[@secondary], 20);
  --secondary-f1: mix(@colors[@primary], @colors[@secondary], 20);
  --secondary-f2: mix(@colors[@primary], @colors[@secondary], 40);
  --accent-d2: darken(@colors[@accent], 20);
  --accent-d1: darken(@colors[@accent], 10);
  --accent: @colors[@accent];
  --accent-l1: lighten(@colors[@accent], 10);
  --accent-l2: lighten(@colors[@accent], 20);
  --error-d1: darken(@colors[@error], 10);
  --error: @colors[@error];
  --error-l1: lighten(@colors[@error], 10);
  --shadow-color: #00000044;
  --slow-key-color: #cc8b00;
  --fast-key-color: #96d760;
  --effort-color: #956eff;
  .use-textinput-colors(@colors);
  .use-interface-colors(@colors);
  .use-chart-colors(@colors, 30);
  .use-keyboard-colors(@colors, 30);
  .use-syntax-colors();
}
