.icon {
  display: block;
  inline-size: 100%;
  block-size: 100%;
}

.trophy {
  fill: #ffd700;
  stroke: #dabf2c;
}
