@import "../../styles/components.less";
@import "../../styles/text.less";

.root {
  .is-blurred();
  .text-truncate();
  display: inline-block;
  position: relative;
  vertical-align: middle;
  min-inline-size: 10rem;
  max-inline-size: 100%;
  min-block-size: @field-height;
  margin: 0;
  padding-inline: 1.5rem;
  padding-block: 0;
  border: none;
  border-radius: 0.25rem;
  color: var(--Button__color);
  background-color: var(--Button__background-color);
  font-family: var(--default-font-family);
  font-size: 0.85rem;
  font-weight: normal;
  font-style: normal;
  line-height: @field-height;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.root:focus-visible {
  color: var(--Button--focused__color);
  background-color: var(--Button--focused__background-color);
  outline: none;
}

.disabled {
  .is-disabled();
}
