.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-inline: 0;
  padding-block: 1rem;
  font-size: 0.75rem;
  text-align: center;
}

.root a {
  color: var(--text-color);
  text-decoration: none;
}

.localeList {
  margin-inline: 2rem;
  margin-block: 0;
}

.localeLink {
  white-space: nowrap;
}
