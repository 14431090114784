.root {
  position: absolute;
  box-sizing: border-box;
  inline-size: 2rem;
  block-size: 2rem;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
}
