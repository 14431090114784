@import "@keybr/widget/lib/index.less";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 3rem;
}

.menu {
  list-style: none;
  max-block-size: 20rem;
  margin: 0;
  padding: 0;
  overflow: auto;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  scrollbar-color: var(--MenuItem--selected__background-color)
    var(--MenuItem__background-color);
  scrollbar-width: auto;
  box-shadow: var(--Popup__box-shadow);
  cursor: pointer;
}

.item {
  .text-truncate();
  display: block;
  block-size: @field-height;
  margin: 0;
  padding: 0;
  padding-inline: 1.5rem;
  padding-block: 0;
  color: var(--MenuItem__color);
  background-color: var(--MenuItem__background-color);
  line-height: @field-height;
}

.item:hover {
  color: var(--MenuItem--hover__color);
  background-color: var(--MenuItem--hover__background-color);
}

.item_selected {
  color: var(--MenuItem--selected__color);
  background-color: var(--MenuItem--selected__background-color);
}

.item_selected:hover {
  color: var(--MenuItem--selected__color);
  background-color: var(--MenuItem--selected__background-color);
}
