@import "palettes.less";

html[data-color="garden"] {
  @colors: {
    @primary: #cee0a4;
    @secondary: #334532;
    @accent: #45633f;
    @error: #e49874;
  };

  --primary-d2: darken(@colors[@primary], 10);
  --primary-d1: darken(@colors[@primary], 5);
  --primary: @colors[@primary];
  --primary-l1: lighten(@colors[@primary], 3);
  --primary-l2: lighten(@colors[@primary], 5);
  --secondary-d1: darken(@colors[@secondary], 10);
  --secondary: @colors[@secondary];
  --secondary-l1: lighten(@colors[@secondary], 10);
  --secondary-l2: lighten(@colors[@secondary], 20);
  --secondary-f1: mix(@colors[@primary], @colors[@secondary], 20);
  --secondary-f2: mix(@colors[@primary], @colors[@secondary], 40);
  --accent-d2: darken(@colors[@accent], 20);
  --accent-d1: darken(@colors[@accent], 10);
  --accent: @colors[@accent];
  --accent-l1: lighten(@colors[@accent], 10);
  --accent-l2: lighten(@colors[@accent], 20);
  --error-d1: darken(@colors[@error], 10);
  --error: @colors[@error];
  --error-l1: lighten(@colors[@error], 10);
  --shadow-color: #00000044;
  --slow-key-color: #cc0000;
  --fast-key-color: #60d788;
  --effort-color: #6699ff;
  .use-textinput-colors(@colors);
  .use-interface-colors(@colors);
  .use-chart-colors(@colors, 0);
  .use-keyboard-colors(@colors, 0);
  .use-syntax-colors();
  --background-image: url(../../assets/bg-garden.svg);
  --Surface__box-shadow: 0 0.5rem 1rem var(--shadow-color);
  --Surface__backdrop-filter: blur(10px);
}
