.char {
  display: inline-block;
  inline-size: 2rem;
  border: 1px solid var(--primary-d2);
  border-radius: 0.5rem;
  background-color: var(--primary-d1);
  font-weight: bold;
  text-align: center;
}

.dead {
  color: var(--KeyboardKey-symbol--dead__color);
}

.ligature {
  color: var(--KeyboardKey-symbol--ligature__color);
}

.unassigned {
  color: var(--text-color-f2);
}
