@import "../../keybr-themes/lib/fonts/arad-400.css";
@import "../../keybr-themes/lib/fonts/arad-700.css";
@import "../../keybr-themes/lib/fonts/cormorant-400.css";
@import "../../keybr-themes/lib/fonts/cormorant-400italic.css";
@import "../../keybr-themes/lib/fonts/cormorant-700.css";
@import "../../keybr-themes/lib/fonts/cormorant-700italic.css";
@import "../../keybr-themes/lib/fonts/newsreader-400.css";
@import "../../keybr-themes/lib/fonts/newsreader-400italic.css";
@import "../../keybr-themes/lib/fonts/newsreader-700.css";
@import "../../keybr-themes/lib/fonts/newsreader-700italic.css";
@import "../../keybr-themes/lib/fonts/nunito-400.css";
@import "../../keybr-themes/lib/fonts/nunito-400italic.css";
@import "../../keybr-themes/lib/fonts/nunito-700.css";
@import "../../keybr-themes/lib/fonts/nunito-700italic.css";
@import "../../keybr-themes/lib/fonts/open-dyslexic-400.css";
@import "../../keybr-themes/lib/fonts/open-dyslexic-400italic.css";
@import "../../keybr-themes/lib/fonts/open-dyslexic-700.css";
@import "../../keybr-themes/lib/fonts/open-dyslexic-700italic.css";
@import "../../keybr-themes/lib/fonts/open-sans-400.css";
@import "../../keybr-themes/lib/fonts/open-sans-400italic.css";
@import "../../keybr-themes/lib/fonts/open-sans-700.css";
@import "../../keybr-themes/lib/fonts/open-sans-700italic.css";
@import "../../keybr-themes/lib/fonts/roboto-400.css";
@import "../../keybr-themes/lib/fonts/roboto-400italic.css";
@import "../../keybr-themes/lib/fonts/roboto-700.css";
@import "../../keybr-themes/lib/fonts/roboto-700italic.css";
@import "../../keybr-themes/lib/fonts/roboto-mono-400.css";
@import "../../keybr-themes/lib/fonts/roboto-mono-400italic.css";
@import "../../keybr-themes/lib/fonts/roboto-mono-700.css";
@import "../../keybr-themes/lib/fonts/roboto-mono-700italic.css";
@import "../../keybr-themes/lib/fonts/rubik-400.css";
@import "../../keybr-themes/lib/fonts/rubik-400italic.css";
@import "../../keybr-themes/lib/fonts/rubik-700.css";
@import "../../keybr-themes/lib/fonts/rubik-700italic.css";
@import "../../keybr-themes/lib/fonts/shantell-sans-400.css";
@import "../../keybr-themes/lib/fonts/shantell-sans-400italic.css";
@import "../../keybr-themes/lib/fonts/shantell-sans-700.css";
@import "../../keybr-themes/lib/fonts/shantell-sans-700italic.css";
@import "../../keybr-themes/lib/fonts/spectral-400.css";
@import "../../keybr-themes/lib/fonts/spectral-400italic.css";
@import "../../keybr-themes/lib/fonts/spectral-700.css";
@import "../../keybr-themes/lib/fonts/spectral-700italic.css";
@import "../../keybr-themes/lib/fonts/ubuntu-400.css";
@import "../../keybr-themes/lib/fonts/ubuntu-400italic.css";
@import "../../keybr-themes/lib/fonts/ubuntu-700.css";
@import "../../keybr-themes/lib/fonts/ubuntu-700italic.css";
@import "../../keybr-themes/lib/fonts/ubuntu-mono-400.css";
@import "../../keybr-themes/lib/fonts/ubuntu-mono-400italic.css";
@import "../../keybr-themes/lib/fonts/ubuntu-mono-700.css";
@import "../../keybr-themes/lib/fonts/ubuntu-mono-700italic.css";
@import "../../keybr-themes/lib/fonts/whitespace-em1000.css";
:where(html) {
  --default-font-family: "Open Sans", sans-serif;
  --header-font-family: "Open Sans", sans-serif;
  --value-font-family: "Ubuntu Mono", monospace;
  --monospace-font-family: "Ubuntu Mono", monospace;
}
html[data-font="cormorant"] {
  --default-font-family: "Cormorant", serif;
  --header-font-family: "Cormorant", serif;
  --value-font-family: "Cormorant", serif;
}
html[data-font="nunito"] {
  --default-font-family: "Nunito", sans-serif;
  --header-font-family: "Nunito", sans-serif;
  --value-font-family: "Nunito", sans-serif;
}
html[data-font="open-sans"] {
  --default-font-family: "Open Sans", sans-serif;
  --header-font-family: "Open Sans", sans-serif;
  --value-font-family: "Ubuntu Mono", monospace;
}
html[data-font="roboto"] {
  --default-font-family: "Roboto", sans-serif;
  --header-font-family: "Roboto", sans-serif;
  --value-font-family: "Roboto Mono", monospace;
}
html[data-font="rubik"] {
  --default-font-family: "Rubik", sans-serif;
  --header-font-family: "Rubik", sans-serif;
  --value-font-family: "Rubik", monospace;
}
html[data-font="shantell-sans"] {
  --default-font-family: "Shantell Sans", sans-serif;
  --header-font-family: "Shantell Sans", sans-serif;
  --value-font-family: "Shantell Sans", sans-serif;
}
html[data-font="spectral"] {
  --default-font-family: "Spectral", serif;
  --header-font-family: "Spectral", serif;
  --value-font-family: "Spectral", serif;
}
html[data-font="ubuntu"] {
  --default-font-family: "Ubuntu", sans-serif;
  --header-font-family: "Ubuntu", sans-serif;
  --value-font-family: "Ubuntu Mono", monospace;
}
html[data-font="sans-serif"] {
  --default-font-family: sans-serif;
  --header-font-family: sans-serif;
  --value-font-family: sans-serif;
}
html[data-font="serif"] {
  --default-font-family: serif;
  --header-font-family: serif;
  --value-font-family: serif;
}
html[data-font="monospace"] {
  --default-font-family: monospace;
  --header-font-family: monospace;
  --value-font-family: monospace;
}
html[data-font="cursive"] {
  --default-font-family: cursive;
  --header-font-family: cursive;
  --value-font-family: cursive;
}
html {
  font-family: var(--default-font-family);
  font-size: 8px;
  line-height: 1.5;
}
@media (min-width: 1280px), (min-device-width: 1280px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1440px), (min-device-width: 1440px) {
  html {
    font-size: 11px;
  }
}
@media (min-width: 1600px), (min-device-width: 1600px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 1920px), (min-device-width: 1920px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 2560px), (min-device-width: 2560px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 3840px), (min-device-width: 3840px) {
  html {
    font-size: 24px;
  }
}
:where(html),
[data-preview-theme] {
  --background-color: var(--primary);
  --background-image: none;
  --text-color: var(--secondary);
  --text-color-f1: var(--secondary-f1);
  --text-color-f2: var(--secondary-f2);
  --separator-color: var(--primary-d1);
  --separator-border: 1px solid var(--separator-color);
  --textinput__color: var(--text-color);
  --textinput--special__color: var(--text-color-f2);
  --textinput--hit__color: var(--text-color-f2);
  --textinput--miss__color: var(--error);
  --textinput-cursor__color: var(--background-color);
  --textinput-cursor__background-color: var(--textinput__color);
  --LessonKey--included__color: var(--secondary-d1);
  --LessonKey--included__background-color: transparent;
  --LessonKey--included__outline-color: transparent;
  --LessonKey--excluded__color: var(--secondary-d1);
  --LessonKey--excluded__background-color: var(--primary-d1);
  --LessonKey--excluded__outline-color: transparent;
  --LessonKey--uncalibrated__color: var(--secondary-d1);
  --LessonKey--uncalibrated__background-color: var(--primary-d1);
  --LessonKey--uncalibrated__outline-color: transparent;
  --LessonKey--focused__outline-color: var(--secondary-l2);
  --LessonKey--forced__outline-color: transparent;
  --LessonKey--current__outline-color: var(--secondary-l2);
  --Keyboard-frame__color: var(--primary-d1);
  --KeyboardKey-button__color: var(--primary);
  --KeyboardKey-button--depressed__color: var(--secondary);
  --KeyboardKey-symbol__color: var(--secondary);
  --KeyboardKey-symbol--depressed__color: var(--primary);
  --KeyboardKey-symbol--dead__color: #cc0000;
  --KeyboardKey-symbol--ligature__color: #6666ff;
  --Chart-frame__color: var(--primary-d2);
  --DailyGoal-bar__color: var(--primary-l2);
  --DailyGoal-frame__color: var(--primary-d2);
  --Calendar-caption__background-color: var(--primary-d1);
  --Calendar-headerCell__background-color: var(--primary-d2);
  --Calendar-cell--background-color: var(--primary-l1);
  --Player__background-color: var(--primary);
  --Player--me__background-color: var(--primary-d1);
  --Player--finished__background-color: transparent;
  --PlayerDetails__color: var(--secondary);
  --PlayerDetails__background-color: var(--primary-l1);
  --Name-color: var(--secondary-l2);
  --Value-color: var(--secondary-d1);
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Button__color: var(--primary-l2);
  --Button__background-color: var(--accent);
  --Button--focused__color: var(--primary-l2);
  --Button--focused__background-color: var(--accent-l2);
  --MenuItem__color: var(--primary-l2);
  --MenuItem__background-color: var(--accent-l1);
  --MenuItem--hover__color: var(--primary-l2);
  --MenuItem--hover__background-color: var(--accent-l2);
  --MenuItem--selected__color: var(--primary-l2);
  --MenuItem--selected__background-color: var(--accent-d1);
  --TextField__color: var(--secondary);
  --TextField__background-color: var(--primary-l1);
  --TextField__border-color: var(--primary-d1);
  --TextField--focused__color: var(--secondary);
  --TextField--focused__background-color: var(--primary-l2);
  --TextField--focused__border-color: var(--primary-d2);
  --Popup__color: var(--text-color);
  --Popup__background-color: var(--background-color);
  --Popup__box-shadow: 0 0.5rem 3rem var(--shadow-color);
  --Popup--small__box-shadow: 0 0rem 1rem var(--shadow-color);
  --Spotlight__background-color: #00000033;
  --Spotlight__border-color: var(--secondary-l2);
  --Spotlight-marker__shadow-color: var(--shadow-color);
  --Surface__box-shadow: none;
  --Surface__backdrop-filter: none;
  scrollbar-color: var(--secondary) var(--primary);
}
:where(html) ::selection,
[data-preview-theme] ::selection {
  color: var(--primary);
  background-color: var(--secondary);
}
@media (prefers-color-scheme: light) {
  html[data-color="system"] {
    --primary-d2: #ded3d3;
    --primary-d1: #e9e1e1;
    --primary: #f4f0f0;
    --primary-l1: #faf9f9;
    --primary-l2: #ffffff;
    --secondary-d1: #141320;
    --secondary: #282640;
    --secondary-l1: #3c3960;
    --secondary-l2: #504c80;
    --secondary-f1: #514e63;
    --secondary-f2: #7a7786;
    --accent-d2: #14181f;
    --accent-d1: #292f3d;
    --accent: #3d475c;
    --accent-l1: #515f7b;
    --accent-l2: #667699;
    --error-d1: #ff0000;
    --error: #ff3333;
    --error-l1: #ff6666;
    --shadow-color: #00000044;
    --slow-key-color: #cc0000;
    --fast-key-color: #60d788;
    --effort-color: #6699ff;
    --textinput__color: #282640;
    --textinput--special__color: #8e8b98;
    --textinput--hit__color: #7a7786;
    --textinput--miss__color: #ff3333;
    --Name-color: #504c80;
    --Value-color: #141320;
    --Value--more__color: #2a7e21;
    --Value--less__color: #a1464e;
    --Chart-speed__color: #6fb48c;
    --Chart-accuracy__color: #ef522f;
    --Chart-complexity__color: #ac71d0;
    --Chart-threshold__color: #d2649a;
    --Chart-hist-h__color: #5f6cb4;
    --Chart-hist-m__color: #b43f3e;
    --Chart-hist-r__color: #b140b4;
    --KeyboardKey-pointer__color: #4ba0f2;
    --pinky-zone-color: #8ec07c;
    --ring-zone-color: #b8bb26;
    --middle-zone-color: #fabd2f;
    --left-index-zone-color: #83a698;
    --right-index-zone-color: #d3869b;
    --thumb-zone-color: #d66354;
    --syntax-keyword: #56a1f4;
    --syntax-string: #72b172;
    --syntax-number: #763a9e;
    --syntax-comment: #9f8484;
  }
}
@media (prefers-color-scheme: dark) {
  html[data-color="system"] {
    --primary-d2: #4d4d4d;
    --primary-d1: #404040;
    --primary: #333333;
    --primary-l1: #2e2e2e;
    --primary-l2: #2b2b2b;
    --secondary-d1: #b8b3b3;
    --secondary: #9f9999;
    --secondary-l1: #867f7f;
    --secondary-l2: #6c6666;
    --secondary-f1: #898585;
    --secondary-f2: #747070;
    --accent-d2: #524d4d;
    --accent-d1: #5f5a5a;
    --accent: #6c6666;
    --accent-l1: #797272;
    --accent-l2: #867f7f;
    --error-d1: #b75c5c;
    --error: #9b4545;
    --error-l1: #783535;
    --shadow-color: #00000088;
    --slow-key-color: #8c1818;
    --fast-key-color: #448154;
    --effort-color: #2d4a86;
    --textinput__color: #9f9999;
    --textinput--special__color: #696666;
    --textinput--hit__color: #747070;
    --textinput--miss__color: #9b4545;
    --Name-color: #d0cece;
    --Value-color: #867f7f;
    --Value--more__color: #2a7e21;
    --Value--less__color: #a1464e;
    --Chart-speed__color: #517460;
    --Chart-accuracy__color: #914331;
    --Chart-complexity__color: #705282;
    --Chart-threshold__color: #834c67;
    --Chart-hist-h__color: #495074;
    --Chart-hist-m__color: #743939;
    --Chart-hist-r__color: #723a74;
    --KeyboardKey-pointer__color: #4ba0f2;
    --pinky-zone-color: #617a58;
    --ring-zone-color: #76772d;
    --middle-zone-color: #977831;
    --left-index-zone-color: #5b6d66;
    --right-index-zone-color: #835d67;
    --thumb-zone-color: #854b44;
    --syntax-keyword: #5991cd;
    --syntax-string: #72b172;
    --syntax-number: #b281d3;
    --syntax-comment: #9f8484;
  }
}
html[data-color="light"] {
  --primary-d2: #ded3d3;
  --primary-d1: #e9e1e1;
  --primary: #f4f0f0;
  --primary-l1: #faf9f9;
  --primary-l2: #ffffff;
  --secondary-d1: #141320;
  --secondary: #282640;
  --secondary-l1: #3c3960;
  --secondary-l2: #504c80;
  --secondary-f1: #514e63;
  --secondary-f2: #7a7786;
  --accent-d2: #14181f;
  --accent-d1: #292f3d;
  --accent: #3d475c;
  --accent-l1: #515f7b;
  --accent-l2: #667699;
  --error-d1: #ff0000;
  --error: #ff3333;
  --error-l1: #ff6666;
  --shadow-color: #00000044;
  --slow-key-color: #cc0000;
  --fast-key-color: #60d788;
  --effort-color: #6699ff;
  --textinput__color: #282640;
  --textinput--special__color: #8e8b98;
  --textinput--hit__color: #7a7786;
  --textinput--miss__color: #ff3333;
  --Name-color: #504c80;
  --Value-color: #141320;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #6fb48c;
  --Chart-accuracy__color: #ef522f;
  --Chart-complexity__color: #ac71d0;
  --Chart-threshold__color: #d2649a;
  --Chart-hist-h__color: #5f6cb4;
  --Chart-hist-m__color: #b43f3e;
  --Chart-hist-r__color: #b140b4;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #8ec07c;
  --ring-zone-color: #b8bb26;
  --middle-zone-color: #fabd2f;
  --left-index-zone-color: #83a698;
  --right-index-zone-color: #d3869b;
  --thumb-zone-color: #d66354;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
}
html[data-color="dark"] {
  --primary-d2: #4d4d4d;
  --primary-d1: #404040;
  --primary: #333333;
  --primary-l1: #2e2e2e;
  --primary-l2: #2b2b2b;
  --secondary-d1: #b8b3b3;
  --secondary: #9f9999;
  --secondary-l1: #867f7f;
  --secondary-l2: #6c6666;
  --secondary-f1: #898585;
  --secondary-f2: #747070;
  --accent-d2: #524d4d;
  --accent-d1: #5f5a5a;
  --accent: #6c6666;
  --accent-l1: #797272;
  --accent-l2: #867f7f;
  --error-d1: #b75c5c;
  --error: #9b4545;
  --error-l1: #783535;
  --shadow-color: #00000088;
  --slow-key-color: #8c1818;
  --fast-key-color: #448154;
  --effort-color: #2d4a86;
  --textinput__color: #9f9999;
  --textinput--special__color: #696666;
  --textinput--hit__color: #747070;
  --textinput--miss__color: #9b4545;
  --Name-color: #d0cece;
  --Value-color: #867f7f;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #517460;
  --Chart-accuracy__color: #914331;
  --Chart-complexity__color: #705282;
  --Chart-threshold__color: #834c67;
  --Chart-hist-h__color: #495074;
  --Chart-hist-m__color: #743939;
  --Chart-hist-r__color: #723a74;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #617a58;
  --ring-zone-color: #76772d;
  --middle-zone-color: #977831;
  --left-index-zone-color: #5b6d66;
  --right-index-zone-color: #835d67;
  --thumb-zone-color: #854b44;
  --syntax-keyword: #5991cd;
  --syntax-string: #72b172;
  --syntax-number: #b281d3;
  --syntax-comment: #9f8484;
}
html[data-color="gray"] {
  --primary-d2: #9a9a9a;
  --primary-d1: #a6a6a6;
  --primary: #b3b3b3;
  --primary-l1: #c0c0c0;
  --primary-l2: #cccccc;
  --secondary-d1: #0c0c0c;
  --secondary: #262626;
  --secondary-l1: #404040;
  --secondary-l2: #595959;
  --secondary-f1: #424242;
  --secondary-f2: #5e5e5e;
  --accent-d2: #1a1a1a;
  --accent-d1: #333333;
  --accent: #4d4d4d;
  --accent-l1: #676767;
  --accent-l2: #808080;
  --error-d1: #7a351f;
  --error: #a34729;
  --error-l1: #cc5933;
  --shadow-color: #00000044;
  --slow-key-color: #cf5f5f;
  --fast-key-color: #6fa882;
  --effort-color: #759ef0;
  --textinput__color: #262626;
  --textinput--special__color: #6d6d6d;
  --textinput--hit__color: #5e5e5e;
  --textinput--miss__color: #a34729;
  --Name-color: #595959;
  --Value-color: #0c0c0c;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #7db494;
  --Chart-accuracy__color: #e36549;
  --Chart-complexity__color: #ad7eca;
  --Chart-threshold__color: #cc749f;
  --Chart-hist-h__color: #707ab4;
  --Chart-hist-m__color: #b45655;
  --Chart-hist-r__color: #b157b4;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #95bd87;
  --ring-zone-color: #b7b942;
  --middle-zone-color: #ecbb49;
  --left-index-zone-color: #8da99d;
  --right-index-zone-color: #cd8fa0;
  --thumb-zone-color: #cf7367;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
}
html[data-color="yellow"] {
  --primary-d2: #ffd500;
  --primary-d1: #ffde33;
  --primary: #ffe666;
  --primary-l1: #ffea80;
  --primary-l2: #ffee99;
  --secondary-d1: #000000;
  --secondary: #26220d;
  --secondary-l1: #4c441a;
  --secondary-l2: #726627;
  --secondary-f1: #51491f;
  --secondary-f2: #7d7031;
  --accent-d2: #26220d;
  --accent-d1: #4d4419;
  --accent: #736626;
  --accent-l1: #998833;
  --accent-l2: #c0aa3f;
  --error-d1: #8a2e0f;
  --error: #b83d14;
  --error-l1: #e64c19;
  --shadow-color: #00000044;
  --slow-key-color: #cc8b00;
  --fast-key-color: #96d760;
  --effort-color: #956eff;
  --textinput__color: #26220d;
  --textinput--special__color: #93843a;
  --textinput--hit__color: #7d7031;
  --textinput--miss__color: #b83d14;
  --Name-color: #726627;
  --Value-color: #000000;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #9ac381;
  --Chart-accuracy__color: #f47e40;
  --Chart-complexity__color: #c594b0;
  --Chart-threshold__color: #e08b8a;
  --Chart-hist-h__color: #8f919d;
  --Chart-hist-m__color: #cb714a;
  --Chart-hist-r__color: #c8729d;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #b0cb75;
  --ring-zone-color: #cdc839;
  --middle-zone-color: #fcc940;
  --left-index-zone-color: #a8b989;
  --right-index-zone-color: #e0a38b;
  --thumb-zone-color: #e28a59;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
}
html[data-color="garden"] {
  --primary-d2: #bad37e;
  --primary-d1: #c4da91;
  --primary: #cee0a4;
  --primary-l1: #d4e4af;
  --primary-l2: #d8e6b7;
  --secondary-d1: #1d271d;
  --secondary: #334532;
  --secondary-l1: #496347;
  --secondary-l2: #5f805d;
  --secondary-f1: #526449;
  --secondary-f2: #718360;
  --accent-d2: #1a2517;
  --accent-d1: #2f442b;
  --accent: #45633f;
  --accent-l1: #5b8253;
  --accent-l2: #729f69;
  --error-d1: #dc7849;
  --error: #e49874;
  --error-l1: #ecb89f;
  --shadow-color: #00000044;
  --slow-key-color: #cc0000;
  --fast-key-color: #60d788;
  --effort-color: #6699ff;
  --textinput__color: #334532;
  --textinput--special__color: #81936b;
  --textinput--hit__color: #718360;
  --textinput--miss__color: #e49874;
  --Name-color: #5f805d;
  --Value-color: #1d271d;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #6fb48c;
  --Chart-accuracy__color: #ef522f;
  --Chart-complexity__color: #ac71d0;
  --Chart-threshold__color: #d2649a;
  --Chart-hist-h__color: #5f6cb4;
  --Chart-hist-m__color: #b43f3e;
  --Chart-hist-r__color: #b140b4;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #8ec07c;
  --ring-zone-color: #b8bb26;
  --middle-zone-color: #fabd2f;
  --left-index-zone-color: #83a698;
  --right-index-zone-color: #d3869b;
  --thumb-zone-color: #d66354;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
  --background-image: url(../../keybr-themes/assets/bg-garden.svg);
  --Surface__box-shadow: 0 0.5rem 1rem var(--shadow-color);
  --Surface__backdrop-filter: blur(10px);
}
html[data-color="coffee"] {
  --primary-d2: #f4d6aa;
  --primary-d1: #f7e1c0;
  --primary: #faecd7;
  --primary-l1: #fdf7ee;
  --primary-l2: #ffffff;
  --secondary-d1: #0c0c0c;
  --secondary: #262626;
  --secondary-l1: #404040;
  --secondary-l2: #595959;
  --secondary-f1: #504e49;
  --secondary-f2: #7b756d;
  --accent-d2: #1a1a1a;
  --accent-d1: #333333;
  --accent: #4d4d4d;
  --accent-l1: #676767;
  --accent-l2: #808080;
  --error-d1: #7a351f;
  --error: #a34729;
  --error-l1: #cc5933;
  --shadow-color: #00000044;
  --slow-key-color: #cf5f5f;
  --fast-key-color: #6fa882;
  --effort-color: #759ef0;
  --textinput__color: #262626;
  --textinput--special__color: #90897f;
  --textinput--hit__color: #7b756d;
  --textinput--miss__color: #a34729;
  --Name-color: #595959;
  --Value-color: #0c0c0c;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #8bbf9b;
  --Chart-accuracy__color: #f17151;
  --Chart-complexity__color: #bc8ad1;
  --Chart-threshold__color: #da7fa6;
  --Chart-hist-h__color: #7e86bb;
  --Chart-hist-m__color: #c2625d;
  --Chart-hist-r__color: #c062bb;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #a4c98e;
  --ring-zone-color: #c5c549;
  --middle-zone-color: #fac651;
  --left-index-zone-color: #9bb4a5;
  --right-index-zone-color: #db9aa7;
  --thumb-zone-color: #dd7e6e;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
  --background-image: url(../../keybr-themes/assets/bg-coffee.svg);
  --Surface__box-shadow: 0 0.5rem 1rem var(--shadow-color);
  --Surface__backdrop-filter: blur(10px);
}
html[data-color="chocolate"] {
  --primary-d2: #42332e;
  --primary-d1: #513e39;
  --primary: #604a43;
  --primary-l1: #6f564d;
  --primary-l2: #7e6158;
  --secondary-d1: #b6b6b6;
  --secondary: #cfcfcf;
  --secondary-l1: #e9e9e9;
  --secondary-l2: #ffffff;
  --secondary-f1: #b9b4b3;
  --secondary-f2: #a39a97;
  --accent-d2: #ce5f3f;
  --accent-d1: #d98167;
  --accent: #e3a390;
  --accent-l1: #edc5b9;
  --accent-l2: #f8e7e1;
  --error-d1: #7a351f;
  --error: #a34729;
  --error-l1: #cc5933;
  --shadow-color: #00000044;
  --slow-key-color: #cf5f5f;
  --fast-key-color: #6fa882;
  --effort-color: #759ef0;
  --textinput__color: #cfcfcf;
  --textinput--special__color: #988d89;
  --textinput--hit__color: #a39a97;
  --textinput--miss__color: #a34729;
  --Name-color: #ffffff;
  --Value-color: #b6b6b6;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #6c9f7d;
  --Chart-accuracy__color: #d25033;
  --Chart-complexity__color: #9d69b4;
  --Chart-threshold__color: #bb5f89;
  --Chart-hist-h__color: #5f659d;
  --Chart-hist-m__color: #a3413f;
  --Chart-hist-r__color: #a1429d;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #85a871;
  --ring-zone-color: #a6a42c;
  --middle-zone-color: #dba633;
  --left-index-zone-color: #7c9487;
  --right-index-zone-color: #bc7a89;
  --thumb-zone-color: #be5e51;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
  --background-image: url(../../keybr-themes/assets/bg-chocolate.svg);
  --Surface__box-shadow: 0 0.5rem 1rem var(--shadow-color);
  --Surface__backdrop-filter: blur(10px);
}
html[data-color="honey"] {
  --primary-d2: #e6ac00;
  --primary-d1: #f0b400;
  --primary: #ffbf00;
  --primary-l1: #ffc51a;
  --primary-l2: #ffcc33;
  --secondary-d1: #130b03;
  --secondary: #402609;
  --secondary-l1: #6d410f;
  --secondary-l2: #995b16;
  --secondary-f1: #664507;
  --secondary-f2: #8c6305;
  --accent-d2: #26190b;
  --accent-d1: #4d3217;
  --accent: #754c22;
  --accent-l1: #9d662d;
  --accent-l2: #c47f39;
  --error-d1: #8a2e0f;
  --error: #b83d14;
  --error-l1: #e64c19;
  --shadow-color: #00000044;
  --slow-key-color: #cc8b00;
  --fast-key-color: #8cc64e;
  --effort-color: #956eff;
  --textinput__color: #402609;
  --textinput--special__color: #a07305;
  --textinput--hit__color: #8c6305;
  --textinput--miss__color: #b83d14;
  --Name-color: #995b16;
  --Value-color: #130b03;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #9ab762;
  --Chart-accuracy__color: #f47321;
  --Chart-complexity__color: #c58892;
  --Chart-threshold__color: #e07f6c;
  --Chart-hist-h__color: #8f857e;
  --Chart-hist-m__color: #cb652b;
  --Chart-hist-r__color: #c8667e;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #b0c057;
  --ring-zone-color: #cdbc1b;
  --middle-zone-color: #fcbe21;
  --left-index-zone-color: #a8ae6a;
  --right-index-zone-color: #e0976d;
  --thumb-zone-color: #e27f3b;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
  --background-image: url(../../keybr-themes/assets/bg-honey.svg);
  --Surface__box-shadow: none;
  --Surface__backdrop-filter: blur(10px);
}
html[data-color="custom"] {
  --primary-l2: #cccccc;
  --primary-l1: #c0c0c0;
  --primary: #b3b3b3;
  --primary-d1: #a6a6a6;
  --primary-d2: #9a9a9a;
  --secondary-d1: #0c0c0c;
  --secondary: #262626;
  --secondary-l1: #404040;
  --secondary-l2: #595959;
  --secondary-f1: #424242;
  --secondary-f2: #5e5e5e;
  --accent-d2: #1a1a1a;
  --accent-d1: #333333;
  --accent: #4d4d4d;
  --accent-l1: #676767;
  --accent-l2: #808080;
  --error-d1: #7a351f;
  --error: #a34729;
  --error-l1: #cc5933;
  --shadow-color: #00000044;
  --slow-key-color: #cc0000;
  --fast-key-color: #60d788;
  --effort-color: #6699ff;
  --textinput__color: #262626;
  --textinput--special__color: #6d6d6d;
  --textinput--hit__color: #5e5e5e;
  --textinput--miss__color: #a34729;
  --Name-color: #595959;
  --Value-color: #0c0c0c;
  --Value--more__color: #2a7e21;
  --Value--less__color: #a1464e;
  --Chart-speed__color: #6fb48c;
  --Chart-accuracy__color: #ef522f;
  --Chart-complexity__color: #ac71d0;
  --Chart-threshold__color: #d2649a;
  --Chart-hist-h__color: #5f6cb4;
  --Chart-hist-m__color: #b43f3e;
  --Chart-hist-r__color: #b140b4;
  --KeyboardKey-pointer__color: #4ba0f2;
  --pinky-zone-color: #8ec07c;
  --ring-zone-color: #b8bb26;
  --middle-zone-color: #fabd2f;
  --left-index-zone-color: #83a698;
  --right-index-zone-color: #d3869b;
  --thumb-zone-color: #d66354;
  --syntax-keyword: #56a1f4;
  --syntax-string: #72b172;
  --syntax-number: #763a9e;
  --syntax-comment: #9f8484;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}
textarea {
  resize: block;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-inline-size: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
a {
  color: var(--text-color);
  text-decoration: underline;
}
a:focus-visible {
  outline: 1px dotted var(--text-color);
}
h1,
h2,
h3 {
  font-family: var(--header-font-family);
}
h1 {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
h1:not(:first-child) {
  margin-block-start: 1rem;
}
h1:not(:last-child) {
  margin-block-end: 1rem;
}
h2 {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
h2:not(:first-child) {
  margin-block-start: 0.8rem;
}
h2:not(:last-child) {
  margin-block-end: 0.8rem;
}
h3 {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
h3:not(:first-child) {
  margin-block-start: 0.6rem;
}
h3:not(:last-child) {
  margin-block-end: 0.6rem;
}
p {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  line-height: 1.5;
  text-align: justify;
}
p:not(:first-child) {
  margin-block-start: 1rem;
}
p:not(:last-child) {
  margin-block-end: 1rem;
}
figure {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 1rem;
  padding-block: 1rem;
  border: var(--separator-border);
}
figure:not(:first-child) {
  margin-block-start: 1rem;
}
figure:not(:last-child) {
  margin-block-end: 1rem;
}
figcaption {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  text-align: center;
}
figcaption:not(:first-child) {
  margin-block-start: 1rem;
}
figcaption:not(:last-child) {
  margin-block-end: 1rem;
}
dl {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
dl:not(:first-child) {
  margin-block-start: 1rem;
}
dl:not(:last-child) {
  margin-block-end: 1rem;
}
dt {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
dd {
  margin-inline: 2rem 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
ol,
ul {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 2rem 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
ol:not(:first-child),
ul:not(:first-child) {
  margin-block-start: 1rem;
}
ol:not(:last-child),
ul:not(:last-child) {
  margin-block-end: 1rem;
}
li {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}
text {
  text-rendering: optimizeLegibility;
}
