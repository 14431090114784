#arrow {
  /* Name only. */
}

.arc {
  fill: none;
}

.h {
  stroke: var(--Chart-hist-h__color);
  stroke-width: 2px;
}

.m {
  stroke: var(--Chart-hist-m__color);
  stroke-width: 2px;
}

.f {
  stroke: var(--text-color);
  stroke-width: 1px;
}
