.root {
  margin: -1rem;
  padding: 1rem;
  backdrop-filter: blur(10px);
}

.hover {
  outline: var(--separator-border);
  outline-offset: 0;
}

.icon {
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: 0;
}
