.textInput_normal {
  margin-block-start: 3rem;
}

.textInput_compact {
  margin-inline: -4rem;
  margin-block-start: 3rem;
}

.textInput_bare {
  margin-inline: -8rem;
  padding-block-start: 10rem;
}

.keyboard {
  margin-block-start: 3rem;
}
