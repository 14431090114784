.pointer {
  fill: transparent;
  stroke: var(--KeyboardKey-pointer__color);
  stroke-opacity: 0.5;
  stroke-width: 5px;
}

.modifierPointer {
  fill: transparent;
  stroke: var(--KeyboardKey-pointer__color);
  stroke-opacity: 0.5;
  stroke-width: 3px;
  stroke-dasharray: 3px;
}
