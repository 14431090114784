@import "./size.less";

.size-fit {
  .size-fit();
}

.size-fill {
  .size-fill();
}

.size-fill-alt {
  .size-fill-alt();
}

.size-full {
  .size-full();
}

.width6 {
  .width6();
}

.width10 {
  .width10();
}

.width16 {
  .width16();
}

.width24 {
  .width24();
}

.width32 {
  .width32();
}
