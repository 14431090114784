.root {
  display: block;
  min-inline-size: auto;
  max-inline-size: 100%;
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 1rem;
  padding-block: 1rem;
  border: var(--separator-border);
}

.legend {
  cursor: pointer;
}
