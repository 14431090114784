.hFlex {
  display: flex;
  flex-direction: row;
}

.vFlex {
  display: flex;
  flex-direction: column;
}

.inlineHFlex {
  display: inline-flex;
  flex-direction: row;
}

.inlineVFlex {
  display: inline-flex;
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.justifyContentStart {
  justify-content: flex-start;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsEnd {
  align-items: flex-end;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsBaseline {
  align-items: baseline;
}

.alignItemsStretch {
  align-items: stretch;
}

.alignContentStart {
  align-content: flex-start;
}

.alignContentEnd {
  align-content: flex-end;
}

.alignContentCenter {
  align-content: center;
}

.alignContentSpaceBetween {
  align-content: space-between;
}

.alignContentSpaceAround {
  align-content: space-around;
}

.alignContentStretch {
  align-content: stretch;
}
