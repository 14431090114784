a {
  color: var(--text-color);
  text-decoration: underline;
}

a:focus-visible {
  outline: 1px dotted var(--text-color);
}

h1,
h2,
h3 {
  font-family: var(--header-font-family);
}

h1 {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

h1:not(:first-child) {
  margin-block-start: 1rem;
}

h1:not(:last-child) {
  margin-block-end: 1rem;
}

h2 {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

h2:not(:first-child) {
  margin-block-start: 0.8rem;
}

h2:not(:last-child) {
  margin-block-end: 0.8rem;
}

h3 {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

h3:not(:first-child) {
  margin-block-start: 0.6rem;
}

h3:not(:last-child) {
  margin-block-end: 0.6rem;
}

p {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  line-height: 1.5;
  text-align: justify;
}

p:not(:first-child) {
  margin-block-start: 1rem;
}

p:not(:last-child) {
  margin-block-end: 1rem;
}

figure {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 1rem;
  padding-block: 1rem;
  border: var(--separator-border);
}

figure:not(:first-child) {
  margin-block-start: 1rem;
}

figure:not(:last-child) {
  margin-block-end: 1rem;
}

figcaption {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  text-align: center;
}

figcaption:not(:first-child) {
  margin-block-start: 1rem;
}

figcaption:not(:last-child) {
  margin-block-end: 1rem;
}

dl {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

dl:not(:first-child) {
  margin-block-start: 1rem;
}

dl:not(:last-child) {
  margin-block-end: 1rem;
}

dt {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

dd {
  margin-inline: 2rem 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

ol,
ul {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 2rem 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

ol:not(:first-child),
ul:not(:first-child) {
  margin-block-start: 1rem;
}

ol:not(:last-child),
ul:not(:last-child) {
  margin-block-end: 1rem;
}

li {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: start;
}

text {
  text-rendering: optimizeLegibility;
}
