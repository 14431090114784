.selfAlignStart {
  align-self: flex-start;
}

.selfAlignEnd {
  align-self: flex-end;
}

.selfAlignCenter {
  align-self: center;
}

.selfAlignBaseline {
  align-self: baseline;
}

.selfAlignStretch {
  align-self: stretch;
}

.flexGrow1 {
  flex: 1;
}

.flexGrow2 {
  flex: 2;
}

.flexGrow3 {
  flex: 3;
}

.flexGrow4 {
  flex: 4;
}

.flexGrow5 {
  flex: 5;
}

.flexOrderFirst {
  order: -1;
}

.flexOrderLast {
  order: 1;
}

.flexOrderNone {
  order: 0;
}
