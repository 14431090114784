@font-face {
  font-family: "Shantell Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(../../assets/shantell-sans-400.cyrillic.woff2) format("woff2");
  unicode-range: U+0400-04FF;
}
@font-face {
  font-family: "Shantell Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(../../assets/shantell-sans-400.latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+2000-206F, U+2200-22FF, U+E000-E003;
}
@font-face {
  font-family: "Shantell Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(../../assets/shantell-sans-400.latin-ext.woff2) format("woff2");
  unicode-range: U+0100-017F, U+0180-024F, U+0300-036F, U+20A0-20CF;
}
