.root {
  padding-block: 1rem;
}

.row {
  display: flex;
  justify-content: center;
}

.cell {
  inline-size: 1rem;
  block-size: 1rem;
  cursor: pointer;
}

.cell:hover {
  z-index: 1;
  outline: 1px solid white;
}
