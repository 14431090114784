.text-center() {
  text-align: center;
}

.text-start() {
  text-align: start;
}

.text-end() {
  text-align: end;
}

.text-no-wrap() {
  white-space: nowrap;
}

.text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
