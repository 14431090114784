@import "./text.less";

.text-center {
  .text-center();
}

.text-start {
  .text-start();
}

.text-end {
  .text-end();
}

.text-no-wrap {
  .text-no-wrap();
}

.text-truncate {
  .text-truncate();
}
