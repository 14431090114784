.root {
  display: block;
  font-kerning: none;
  font-variant: none;
  line-height: 1.2;
  user-select: none;
}

.wrap {
  overflow: visible;
  white-space: break-spaces;
}

.nowrap {
  overflow: visible;
  white-space: nowrap;
}

.focus {
  filter: none;
}

.blur {
  filter: blur(3px);
}

.size_X0 {
  font-size: 2rem;
}

.size_X1 {
  font-size: 3rem;
}

.size_X2 {
  font-size: 4rem;
}

.size_X3 {
  font-size: 5rem;
}
