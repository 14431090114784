.track {
  position: relative;
  margin-inline: 1rem;
  margin-block: 0;
  box-shadow: var(--Surface__box-shadow);
  backdrop-filter: var(--Surface__backdrop-filter);
}

.ticker {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  text-align: center;
}

.center {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.flagImage {
  display: block;
  inline-size: 15rem;
  block-size: 15rem;
  filter: drop-shadow(16px 16px 10px rgba(64, 64, 64, 0.5));
}
