.root {
  content: "";
}

.size1 {
  margin-block-start: 1rem;
}

.size2 {
  margin-block-start: 2rem;
}

.size3 {
  margin-block-start: 3rem;
}

.size4 {
  margin-block-start: 4rem;
}

.size5 {
  margin-block-start: 5rem;
}

.size10 {
  margin-block-start: 10rem;
}
