.body {
  block-size: 100vb;
}

.center {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  block-size: 100%;
}

.content {
  inline-size: 25rem;
  margin-block-end: 4rem;
}
