@import "@keybr/widget/lib/index.less";

.row {
  display: flex;
  align-items: baseline;
  padding-block-start: 0.4rem;
}

.name {
  .text-truncate();
  inline-size: 10rem;
  padding-inline-end: 1rem;
  text-align: end;
}

.value {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
}
