.root {
  inline-size: 14rem;
}

.tabs {
  display: flex;
}

.tab {
  flex: 1;
  text-align: center;
  cursor: pointer;
}
