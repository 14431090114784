.root {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.customImage {
  border-radius: 50%;
}

.anonymousImage {
  fill: var(--Button__background-color);
}

.size_normal {
  inline-size: 2rem;
  block-size: 2rem;
}

.size_medium {
  inline-size: 3rem;
  block-size: 3rem;
}

.size_large {
  inline-size: 4rem;
  block-size: 4rem;
}
