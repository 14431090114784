body {
  overflow-x: auto; // For Chrome 129
  overflow-inline: auto;
  overflow-y: scroll; // For Chrome 129
  overflow-block: scroll;
  color: var(--text-color);
  background-color: var(--background-color);
  background-image: var(--background-image, none);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

:fullscreen {
  overflow: auto !important;
  color: var(--text-color);
  background-color: var(--background-color);
  background-image: var(--background-image, none);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
