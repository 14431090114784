.keyboard {
  contain: content;
  display: block;
  inline-size: 100%;
  margin-inline: auto;
  margin-block: 0;
}

.frame {
  fill: var(--Keyboard-frame__color);
}
