.cell {
  display: inline-block;
  background-color: var(--Calendar-cell--background-color);
  cursor: default;
}

.item {
  display: inline-block;
  inline-size: 4rem;
  padding-block: 0.25rem;
  text-align: center;
}
