.root {
  max-block-size: 30rem;
  padding-inline-end: 1rem;
  overflow-x: auto; // For Chrome 129
  overflow-inline: auto;
  overflow-y: scroll; // For Chrome 129
  overflow-block: scroll;
  overscroll-behavior: contain;
}

.table {
  table-layout: fixed;
  inline-size: 100%;
  border: var(--separator-border);
  border-collapse: collapse;
}

.table thead {
  position: sticky;
  inset-block-start: 0;
  background-color: var(--primary);
}

.row {
  border-block-end: var(--separator-border);
}

.keyCol {
  padding-inline: 1rem;
  text-align: start;
}

.characterCol {
  inline-size: 20%;
  padding-inline: 1rem;
  text-align: start;
}
