@import "../../styles/components.less";

.root {
  .is-blurred();
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  min-block-size: @field-height;
  line-height: @field-height;
  cursor: default;

  input {
    position: absolute;
    inline-size: 0;
    block-size: 0;
    border: none;
    opacity: 0;
    appearance: none;
  }
}

.icon {
  inline-size: @field-height;
  block-size: @field-height;
  fill: var(--Button__background-color);
}

.root:focus-within {
  .icon {
    .is-focused();
    outline-offset: 2px;
  }
}

.label {
  block-size: @field-height;
}

.icon + .label {
  margin-inline-start: 0.5rem;
}

.disabled {
  .is-disabled();
}
