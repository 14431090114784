.root {
  contain: content;
  position: relative;
  margin-inline: -1rem;
  margin-block: -1rem;
  padding-inline: 1rem;
  padding-block: 1rem;
}

.messageArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageText {
  padding-inline: 2rem;
  padding-block: 0.5rem;
  border: var(--separator-border);
  color: var(--Popup__color);
  background-color: var(--Popup__background-color);
  box-shadow: var(--Popup--small__box-shadow);
}
