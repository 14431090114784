.root {
  display: flex;
  position: fixed;
  inset: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 2rem;
  color: var(--Popup__color);
  background-color: var(--Popup__background-color);
  box-shadow: var(--Popup__box-shadow);
}
