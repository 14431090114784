/**
 * Make an element shrink wrap its content.
 */

.size-fit() {
  flex-basis: auto;
}

/**
 * Make an element fill the remaining space.
 */

.size-fill() {
  flex: 1 1 0;
}

/**
 * An alternative method to make an element fill the remaining space.
 * Distributes space based on the initial width and height of the element
 *
 * https://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
 */

.size-fill-alt() {
  flex: 1 1 auto;
}

/**
 * Make an element the width of its parent.
 */

.size-full() {
  display: block !important;
  inline-size: 100% !important;
  min-inline-size: 100% !important;
}

.width6() {
  inline-size: 6rem !important;
  min-inline-size: 6rem !important;
}

.width10() {
  inline-size: 10rem !important;
  min-inline-size: 10rem !important;
}

.width16() {
  inline-size: 16rem !important;
  min-inline-size: 16rem !important;
}

.width24() {
  inline-size: 24rem !important;
  min-inline-size: 24rem !important;
}

.width32() {
  inline-size: 32rem !important;
  min-inline-size: 32rem !important;
}
