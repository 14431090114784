.root {
  inline-size: 40rem;
  max-block-size: 40rem;
}

.scroll {
  padding-inline-end: 1rem;
  overflow-y: auto; // For Chrome 129
  overflow-block: auto;
}
